import React, { useState } from "react";
import { NonDeletedExcalidrawElement } from "../element/types";
import { t } from "../i18n";

import { ExportOpts, BinaryFiles, UIAppState } from "../types";
import { Dialog } from "./Dialog";
import { ToolButton } from "./ToolButton";
import { Card } from "./Card";

import "./ExportDialog.scss";
import { nativeFileSystemSupported } from "../data/filesystem";
import { ActionManager } from "../actions/manager";

export type ExportCB = (
  elements: readonly NonDeletedExcalidrawElement[],
  scale?: number,
) => void;

const JSONExportModal = ({
  elements,
  appState,
  setAppState,
  files,
  actionManager,
  exportOpts,
  canvas,
  onCloseRequest,
}: {
  appState: UIAppState;
  setAppState: React.Component<any, UIAppState>["setState"];
  files: BinaryFiles;
  elements: readonly NonDeletedExcalidrawElement[];
  actionManager: ActionManager;
  onCloseRequest: () => void;
  exportOpts: ExportOpts;
  canvas: HTMLCanvasElement;
}) => {
  // Structure the data in the expected .excalidraw format
  const exportData = {
    type: "excalidraw",
    version: 2,
    source: window.location.origin, // Use current origin
    elements,
    appState: {
      ...appState,
      // You can include or exclude appState properties as needed
    },
    files,
  };

  const [jsonString, setJsonString] = useState<string>(
    JSON.stringify(exportData, null, 2) // Pretty print the JSON with indentation
  );

  return (
    <div className="ExportDialog ExportDialog--json">
      <div className="ExportDialog-cards">
        <Card color="lime">
          <h2>{t("exportDialog.copyJson_title")}</h2> {/* Ensure this key exists in your translation files */}
          <div className="Card-details">
            {t("exportDialog.copyJson_details")} {/* Ensure this key exists in your translation files */}
          </div>
          <textarea
            value={jsonString}
            readOnly
            rows={10}
            style={{ width: "100%" }}
          />

        </Card>

        {exportOpts.renderCustomUI &&
          exportOpts.renderCustomUI(elements, appState, files, canvas)}
      </div>
    </div>
  );
};

export const JSONExportDialog = ({
  elements,
  appState,
  files,
  actionManager,
  exportOpts,
  canvas,
  setAppState,
}: {
  elements: readonly NonDeletedExcalidrawElement[];
  appState: UIAppState;
  files: BinaryFiles;
  actionManager: ActionManager;
  exportOpts: ExportOpts;
  canvas: HTMLCanvasElement;
  setAppState: React.Component<any, UIAppState>["setState"];
}) => {
  const handleClose = React.useCallback(() => {
    setAppState({ openDialog: null });
  }, [setAppState]);

  return (
    <>
      {appState.openDialog === "jsonExport" && (
        <Dialog onCloseRequest={handleClose} title={t("buttons.export")}>
          <JSONExportModal
            elements={elements}
            appState={appState}
            setAppState={setAppState}
            files={files}
            actionManager={actionManager}
            onCloseRequest={handleClose}
            exportOpts={exportOpts}
            canvas={canvas}
          />
        </Dialog>
      )}
    </>
  );
};