import "./ExcalidrawLogo.scss";

const LogoIcon = () => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="ExcalidrawLogo-icon"
  >
    <path fill="#2686F4" d="M10.2,0.5H20v9.8h-9.8V0.5z M20,0.5h9.8v9.8H20V0.5z M0.5,10.2h9.8V20H0.5V10.2z M10.2,10.2H20V20h-9.8V10.2z
	 M20,10.2h9.8V20H20V10.2z M29.8,10.2h9.8V20h-9.8V10.2z M0.5,20h9.8v9.8H0.5V20z M10.2,20H20v9.8h-9.8V20z M20,20h9.8v9.8H20V20z
	 M29.8,20h9.8v9.8h-9.8V20z M10.2,29.8H20v9.8h-9.8V29.8z M20,29.8h9.8v9.8H20V29.8z M29.8,0.5h0.5c5.1,0,9.3,4.2,9.3,9.3v0.6h-9.8
	V0.5L29.8,0.5z M9.8,0.5h0.5v9.8H0.5V9.8C0.5,4.6,4.6,0.5,9.8,0.5z M29.8,29.8h9.8v0.5c0,5.1-4.2,9.3-9.3,9.3h-0.5V29.8L29.8,29.8z
	 M0.5,29.8h9.8v9.8H9.8c-5.1,0-9.3-4.2-9.3-9.3L0.5,29.8C0.5,29.8,0.5,29.8,0.5,29.8z"/>
    <path fill="#FAFAFA" d="M25.4,22h4.3v7.7h-4.3V22z M17.3,10.2c-1.8,0-3.4,0.7-4.6,1.9c-1.5,1.4-2.4,3.5-2.4,5.8V22
	c0,2.1,0.8,4.1,2.1,5.5c0.1,0.1,0.2,0.2,0.4,0.4c1.2,1.2,2.9,1.9,4.6,1.9h4.3V22h-4.3V18h12.4v-7.7L17.3,10.2L17.3,10.2z"/>
  </svg>
);

const LogoText = () => (
  <svg
    viewBox="0 0 450 55"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className="ExcalidrawLogo-text"
  >
    <path fill="currentColor" d="M60.8,24.8v26H3.3v-35c0-2.6,0.8-4.7,2.3-6.4c1.5-1.6,4-2.5,7.5-2.5h47.8v11.5H16.7v20.9h30.9V24.8H60.8z M67.3,23h29.7
	v11.5H67.3V23z M123.5,18.4h-19.2L95.5,6.9H158v11.5h-21.2v32.4h-13.3V18.4z M148.2,50.8l13.7-23h20.7l-3.4-5.9h-13.7l8.6-15h11
	l26.1,43.9h-15l-7.1-11.5h-18.9l-7.3,11.5H148.2z M260.4,23.1v-4.7h-44V6.9h57.3v16.5c0,0.1-0.1,0.4-0.2,0.8s-0.3,1-0.5,1.5
	c-0.2,0.6-0.4,1.1-0.7,1.6c-0.2,0.5-0.5,0.9-0.7,1.1c0.3,0.4,0.5,0.8,0.8,1.3c0.2,0.5,0.5,1,0.7,1.4s0.4,0.9,0.5,1.3
	s0.2,0.7,0.2,0.8v8c0,1.4-0.3,2.7-0.8,3.9c-0.5,1.2-1.2,2.2-2.1,3s-1.8,1.5-2.9,2c-1.1,0.5-2.1,0.7-3.1,0.7h-48.4V23.1H260.4z
	 M260.4,39.3v-4.8h-30.7v4.8H260.4z M280.3,6.9h13.3v32.4h33.1v11.5h-46.4V6.9z M333.3,6.9h51.1v11.5h-51.1V6.9z M333.3,23h38.1
	v11.5h-38.1V23z M333.3,39.2h51v11.6h-51V39.2z M412.2,18.4H391V6.9h55.8v11.5h-21.2v32.4h-13.3V18.4z"/>
  </svg>
);

type LogoSize = "xs" | "small" | "normal" | "large" | "custom";

interface LogoProps {
  size?: LogoSize;
  withText?: boolean;
  style?: React.CSSProperties;
  /**
   * If true, the logo will not be wrapped in a Link component.
   * The link prop will be ignored as well.
   * It will merely be a plain div.
   */
  isNotLink?: boolean;
}

export const ExcalidrawLogo = ({
  style,
  size = "small",
  withText,
}: LogoProps) => {
  return (
    <div className={`ExcalidrawLogo is-${size}`} style={style}>
      <LogoIcon />
      {withText && <LogoText />}
    </div>
  );
};
